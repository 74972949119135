import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import CollectionDriveContent from '../components/Programs/CollectionDriveContent';

export const CollectionDrivePageTemplate = ({ hero, blurb, link }) => {
  return (
    <div className="w-full flex flex-col bg-s4tk-white">
      <CollectionDriveContent hero={hero} blurb={blurb} link={link} />
    </div>
  );
};

const CollectionDrivePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <CollectionDrivePageTemplate
        hero={frontmatter.hero}
        blurb={frontmatter.blurb}
        link={frontmatter.link}
      />
    </Layout>
  );
};

export default CollectionDrivePage;

export const collectionDrivePageQuery = graphql`
  query CollectionDrivePageTemplate {
    markdownRemark(
      frontmatter: { templateKey: { eq: "collection-drive-page" } }
    ) {
      frontmatter {
        hero {
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          text
        }
        blurb
        link {
          title
          icon {
            prefix
            title
          }
          url
        }
      }
    }
  }
`;
