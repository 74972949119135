import React from 'react';
import Section from '../Section';
import Hero from '../Hero';
import SmallLink from '../SmallLink';

export const CollectionDriveContent = ({ hero, blurb, link }) => {
  return (
    <>
      <Hero image={hero.image} title={hero.text} />
      <Section>
        <p className="text-3xl font-baloo text-black text-left sm:text-justify mb-4">
          {blurb}
        </p>
        <SmallLink item={link} />
      </Section>
    </>
  );
};

export default CollectionDriveContent;
